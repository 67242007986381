<template>
	<ConfirmDialog></ConfirmDialog>
	<ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
		<li>
			<a class="text-500 no-underline line-height-3 cursor-pointer">Ventas/Clientes</a>
		</li>
		<li class="px-2">
			<i class="pi pi-angle-right text-500 line-height-3"></i>
		</li>
		<li>
			<span class="text-900 line-height-3">Grupos de Descuento</span>
		</li>
	</ul>
	<div class="surface-section px-4 py-5 md:px-12 lg:px-12">
		<Toast />
		<div
			class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
		>
			<div>
				<div class="font-medium text-3xl text-900">Administrar Grupos Cliente Descuentos <Badge size="xlarge" severity="success"> {{ totalRegistros }}</Badge></div>
			</div>
			<div class="flex mt-3 lg:mt-0">
				<Button
					label="Nuevo"
					v-if="'GrupoDesCliente Crear' in auth.user.permissions"
					v-tooltip.top="'Nuevo Grupo Cliente Descuento'"
					icon="pi pi-plus"
					class="p-button-outlined mr-2 p-button-lg"
					@click="openNuevo"
				/>
			</div>
		</div>
		<div class="mt-4">
			<DataTable
				ref="dtgrupodesc"
				:value="grupoClienteDescuentos"
				:loading="cargando"
				dataKey="id"
				:paginator="true"
				:rows="10"
				:rowHover="true"
				:filters="buscar"
				class="p-datatable-sm"
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				:rowsPerPageOptions="[10, 50, 100]"
				currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Grupos de Descuento"
				responsiveLayout="scroll"
				showGridlines
			>
				<template #header>
					<div
						class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
					>
						<span class="block mt-2 md:mt-0 p-input-icon-left">
							<i class="pi pi-search" />
							<InputText
								v-model="buscar['global'].value"
								placeholder="Buscar..."
							/>
						</span>
					</div>
				</template>

				<template #loading>
					<div class="flex align-items-center justify-content-center">
						<ProgressSpinner />
					</div>
				</template>
				<template #empty>
					<span class="flex align-items-center justify-content-center p-invalid"
						>No existen Registros!</span
					></template
				>
				<Column header="#">
					<template #body="slotProps">
						{{ 1 + slotProps.index }}
					</template>
				</Column>
				<Column field="id" header="COD_GRUPO" style="text-align: right"></Column>
        <Column field="nombre_grupo" header="NOMBRE GRUPO"></Column>
        <Column field="porcentaje_descuento" header="PORCENTAJE DESCUENTO" style="text-align: right">
          <template #body="{ data }">
            {{ convertirNumeroGermanicFormat(data.porcentaje_descuento) + "%" }}
          </template>
        </Column>
        <Column field="created_at" header="FECHA CREACIÓN">
          <template #body="{ data }">
            {{ formatDate(data.created_at) }}
          </template>
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado]">
              {{ data.estado_texto }}
            </span>
          </template>
        </Column>
				<Column header="ACCIONES">
					<template #body="slotProps">
						<Button
							class="p-button-icon-only p-button-raised"
							v-tooltip.top="'Ver Acciones'"
							icon="pi pi-ellipsis-v"
							@click="$refs['menu' + slotProps.data.id].toggle($event)"
						/>
						<Menu
							:ref="'menu' + slotProps.data.id"
							:popup="true"
							:model="acciones(slotProps.data)"
						>
						</Menu>
					</template>
				</Column>
			</DataTable>
		</div>
		<GrupoDescCreate
			:show="grupoDescCreateDialog"
			:grupoDescCliente="grupoDescCliente"
			@closeModal="ocultarDialog"
			@actualizarListado="cargaGrupoDescCliente"
		>
		</GrupoDescCreate>
	</div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { useAuth } from "@/stores";
import GrupoDescCreate from "@/module/grupo_desc_cliente/GrupoDescCreate";
import GrupoDescClienteService from "@/service/GrupoDescClienteService";

export default {
	components: {
		GrupoDescCreate,
	},
	data() {
		return {
			grupoClienteDescuentos: [],
			cargando: true,
			grupoDescCreateDialog: false,
			deleteMedicoDialog: false,
			grupoDescCliente: {},
			buscar: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
			enviado: false,
			estados: [
				{ label: "INACTIVO", value: 0 },
				{ label: "ACTIVO", value: 1 },
			],
			errors: {},
			totalRegistros: 0,
      claseEstado: ["status-outofstock", "status-instock"],
		};
	},
	grupoDescClienteService: null,
	auth: null,
	created() {
		this.auth = useAuth();
		this.grupoDescClienteService = new GrupoDescClienteService();
	},
	mounted() {
		this.cargaGrupoDescCliente();
	},
	methods: {
		acciones(datos) {
			let permisosUsuarioAuth = this.auth.user.permissions;
			return [
				{
					label: "Ver Clientes",
					disabled: "GrupoDesCliente Crear" in permisosUsuarioAuth ? false : true,
					icon: "pi pi-eye",
					to:`/grupo_desc_cliente/${datos.id}/detalle`,
				},
				{
					label: "Editar",
					disabled: "GrupoDesCliente Editar" in permisosUsuarioAuth ? false : true,
					icon: "pi pi-pencil",
					command: () => {
						this.editGrupoDescCliente(datos);
					},
				},
				{
					label: "Eliminar",
					disabled: "GrupoDesCliente Eliminar" in permisosUsuarioAuth ? false : true,
					icon: "pi pi-trash",
					command: () => {
						this.deletedGrupoDescCliente(datos.id);
					},
				},
			];
		},
    deletedGrupoDescCliente(id) {
			this.$confirm.require({
        header: "CONFIRMACIÓN",
          message: "¿Está seguro de eliminar el Grupo de Descuento?",
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "SI, Eliminar",
          rejectLabel: "No, Cancelar",
          acceptClass: "p-button-primary p-button-lg",
          rejectClass: "p-button-danger p-button-lg",
          acceptIcon: "pi pi-save",
          rejectIcon: "pi pi-times",
				accept: () => {
					this.grupoDescClienteService.deleteGrupoDesCliente(id)
						.then((response) => {
							if(response.status == 200){
                this.$toast.add({
                  severity: "success",
                  summary: "Éxito",
                  detail: response.mensaje,
                  life: 3000,
                });
                this.cargaGrupoDescCliente();
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.mensaje,
                  life: 3000,
                });
              }
            })
				},
			});
		},
		cargaGrupoDescCliente() {
			this.grupoDescClienteService.getGrupoDescCliente().then((data) => {
				this.grupoClienteDescuentos = data.grupoClienteDescuento;
				this.cargando = false;
			});
		},
		openNuevo() {
			this.grupoDescCliente = {
				estado: { label: "Activo", value: 1 },
			};
			this.enviado = false;
			this.grupoDescCreateDialog = true;
		},
		ocultarDialog() {
			this.grupoDescCreateDialog = false;
			this.enviado = false;
		},
    editGrupoDescCliente(datos) {
      this.grupoDescCliente = {
        ...datos,
        estado: {
          label: datos.estado_texto,
          value: datos.estado,
        },
        porcentaje_descuento: parseFloat(datos.porcentaje_descuento),
      };
      this.grupoDescCreateDialog = true;

      this.enviado = false;
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString([], {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        minute: "2-digit",
        hour: "2-digit",
        second: "2-digit",
      });
    },
	},
	watch: {
		grupoClienteDescuentos() {
			this.totalRegistros = this.grupoClienteDescuentos.length;
		},
	},
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
	color: red;
}
</style>