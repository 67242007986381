import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class GrupoDescClienteService {

  async getGrupoDescCliente() {
    const grupo_clientes = await fetchWrapper.get(`${ruta}/grupo_cliente_descuentos`);
    return grupo_clientes;
  }

  async sendGrupoDesClienteNew(data) {
    const grupo_clientes = await fetchWrapper.post(`${ruta}/grupo_cliente_descuentos`, data);
    return grupo_clientes;
  }

  async updateGrupoDesCliente(data) {
    const grupo_clientes = await fetchWrapper.put(`${ruta}/grupo_cliente_descuentos/${data.id}`, data);
    return grupo_clientes;
  }

  async deleteGrupoDesCliente(id) {
    const grupo_clientes = await fetchWrapper.delete(`${ruta}/grupo_cliente_descuentos/${id}`);
    return grupo_clientes;
  }
}