<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '450px' }"
    header="GRUPO DESCUENTO - DETALLE"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <label for="nombre"
            ><strong>NOMBRE GRUPO: </strong>
            <span class="p-invalid">*</span></label
          >
          <InputText
            id="mombre_grupo"
            v-model.trim="data_grupo_desc.nombre_grupo"
            required="true"
            placeholder="Nombre Grupo"
            autofocus
          />
          <small class="p-invalid" v-if="errors.nombre_grupo">{{
            errors.nombre_grupo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-8">
          <label for="porcentaje_descuento"
            ><strong>PORCENTAJE DESCUENTO: </strong>
            <span class="p-invalid">*</span></label
          >
          <InputNumber
            id="porcentaje_descuento"
            v-model="data_grupo_desc.porcentaje_descuento"
            required="true"
            placeholder="Porcentaje Descuento"
            showButtons
            :inputClass="'text-right'"
            :min="0"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            locale="de-ED"
          />
          <small class="p-invalid" v-if="errors.porcentaje_descuento">{{
            errors.porcentaje_descuento[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="estado"
            ><strong>ESTADO: </strong><span class="p-invalid">*</span></label
          >
          <Dropdown
            id="estado"
            v-model="data_grupo_desc.estado"
            :disabled="!data_grupo_desc.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado..."
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
        :disabled="!enviado ? false : true"
      />
      <Button
        :label="data_grupo_desc.id ? 'ACTUALIZAR' : 'GUARDAR'"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        @click="saveGrupoDescCliente"
        :disabled="!enviado ? false : true"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>

<script>
import GrupoDescClienteService from "@/service/GrupoDescClienteService";
export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    grupoDescCliente: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  grupoDescService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_grupo_desc: this.grupoDescCliente,
      enviado: false,
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
    };
  },
  created() {
    this.grupoDescService = new GrupoDescClienteService();
  },
  methods: {
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
    },
    saveGrupoDescCliente() {
      this.errors = {};
      this.enviado = true;
      //editar grupoDescCliente
      if (this.grupoDescCliente.id) {
        let paqueteproducto_enviar = {
          ...this.data_grupo_desc,
          estado: this.data_grupo_desc.estado.value,
        };
        this.grupoDescService
          .updateGrupoDesCliente(paqueteproducto_enviar)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.mensaje,
                life: 3000,
              });
              this.$emit("actualizarListado");
              this.ocultarDialog();
            }
            this.enviado = false;
          });
      } else {
        //CREANDO UN NUEVO PROVEEDOR
        let tmp = this.data_grupo_desc;
        this.data_grupo_desc = {
          ...this.data_grupo_desc,
          estado: this.data_grupo_desc.estado.value,
        };
        this.grupoDescService
          .sendGrupoDesClienteNew(this.data_grupo_desc)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
              this.data_grupo_desc = tmp;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.mensaje,
                life: 10000,
              });
              this.$emit("actualizarListado", data.grupoDescCliente);
              this.ocultarDialog();
            }
            this.enviado = false;
          });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    grupoDescCliente(val) {
      this.data_grupo_desc = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
